import React from 'react'
import HeroSection from '../components/HeroSection'
import FeaturesSection from '../components/FeaturesSection'
import ClientsSection from '../components/ClientsSection'
import TestimonialsSection from '../components/TestimonialsSection'
import CtaSection from '../components/CtaSection'
import Navbar from '../components/Navbar'
import Divider from '../components/Divider'
import Announcement from '../components/Announcement'
import Footer from '../components/Footer'
import SEO from '../components/seo'
import { graphql } from 'gatsby'
import { navigate } from '@reach/router'

function HomePage({ data, location }) {
  return (
    <>
      {/* <Announcement
        color="primary"
        size="danger"
        text="We're hiring! Join us in building the digital health infrastructure!"
        buttons={[
          {
            buttonText: 'Apply',
            buttonOnClick:
              'https://www.notion.so/Akute-Health-Careers-5b3135512c8c477d9b43bb3cae23a509',
          },
          // {
          //   buttonText: 'Learn More',
          //   buttonOnClick:
          //     '/resource-center/covid-19-small-business-relief-programs',
          // },
        ]}
        buttonText="Apply"
        buttonOnClick={() => {
          navigate(
            'https://www.notion.so/Akute-Health-Careers-5b3135512c8c477d9b43bb3cae23a509'
          )
        }}
      /> */}
      <Navbar color="white" spaced={true} data={data.allContentfulNavbar} />
      <SEO title={data.allContentfulHomePage.edges[0].node.title} />
      <HeroSection
        color="white"
        size="medium"
        data={data.allContentfulHomePage}
        buttonText="Request a Demo"
        // image="https://uploads.divjoy.com/undraw-japan_ubgk.svg"
        image={data.allContentfulHomePage.edges[0].node.bannerImage.fixed.src}
        subscribedMessage="Thanks! We will reach out to you shortly."
        formName="/"
        // buttonOnClick={() => {
        //   router.push('/pricing')
        // }}
      />
      <ClientsSection color="light" size="small" title="Trusted by" />
      <FeaturesSection
        color="white"
        size="medium"
        features={
          data.allContentfulHomePage.edges[0].node
            .childContentfulHomePageFeatureListJsonNode.feature
        }
        images={data.allContentfulHomePage.edges[0].node.featureImages}
      />
      <TestimonialsSection
        color="white"
        size="medium"
        title="Here's what people are saying"
        subtitle=""
      />
      <CtaSection
        color="primary"
        size="medium"
        title="Want to learn more?"
        subtitle=""
        buttonText="Request a Demo"
        subscribedMessage="Thanks! We will reach out to you shortly."
        formName="cta"
        // buttonOnClick={() => {
        //   router.push('/pricing')
        // }}
      />
      <Divider color="light" />
      <Footer
        color="light"
        size="medium"
        logo={data.allContentfulNavbar.edges[0].node.brandLogo}
        description={data.allContentfulFooter.edges[0].node.description}
        copyright={
          '© ' +
          data.allContentfulFooter.edges[0].node.companyName +
          ` ${new Date().getFullYear()}`
        }
        socialPages={data.allContentfulFooter.edges[0].node.socialPages}
        socialImages={data.allContentfulFooter.edges[0].node.socialImages}
        driftAppId={data.allContentfulFooter.edges[0].node.driftAppId}
      />
    </>
  )
}

export default HomePage

export const pageQuery = graphql`
  query {
    allContentfulNavbar {
      edges {
        node {
          brandLogo {
            fixed(width: 250) {
              width
              height
              src
              srcSet
            }
            fluid(maxWidth: 1000) {
              src
            }
          }
          links
        }
      }
    }
    allContentfulHomePage {
      edges {
        node {
          title
          subtitle
          whyTitle
          whySubtitle
          bannerImage {
            fixed(width: 1000) {
              width
              height
              src
              srcSet
            }
            title
          }
          brands {
            file {
              url
            }
            title
          }
          childContentfulHomePageFeatureListJsonNode {
            feature {
              title
              subtitle
            }
          }
          featureImages {
            title
            fluid(maxWidth: 700) {
              ...GatsbyContentfulFluid
            }
          }
          stepNames
          step1
          step2
          step3
        }
      }
    }
    allContentfulStyles {
      edges {
        node {
          childContentfulStylesButtonJsonNode {
            backgroundColor
            color
          }
        }
      }
    }
    allContentfulFooter {
      edges {
        node {
          companyName
          description
          companyContactEmail
          driftAppId
          socialPages
          socialImages {
            title
            fixed(width: 20) {
              width
              height
              src
              srcSet
            }
          }
        }
      }
    }
  }
`
